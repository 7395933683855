import React, { useEffect, useState } from 'react';
import { Guest } from './types';
import YouTube from "react-youtube";

function Login(props: {
  familyId: number,
  onGuestsFetched: (guests: Array<Guest>) => any,
  answer: string,
  setAnswer: (answer: string) => any
}) {

  let [question, setQuestion] = useState("Loading Question... If this takes too long contact Salima or Khallil as something might be broken or maybe you're not supposed to be here");
  let [nameString, setNameString] = useState("...");

  useEffect(() => {
    // fetch(`http://localhost:9000/api/familyQuestion/${props.familyId}`)
    fetch(`https://kenyamarryme.herokuapp.com/api/familyQuestion/${props.familyId}`)
      .then(res => res.json())
      .then((res: {question: string, nameString: string}) => {
        if (res.question) {
          setQuestion(res.question);
          setNameString(res.nameString);
        }
      })
  }, [])

  const submitAnswer = () => {
    // fetch(`http://localhost:9000/api/family/${props.familyId}?answer=${props.answer}`)
    fetch(`https://kenyamarryme.herokuapp.com/api/family/${props.familyId}?answer=${props.answer}`)
      .then(res => res.json())
      .then((res: any) => {
        if (res.error) {
          // handle error
        } else {
          props.onGuestsFetched(res.guests)
        }
      })
  }

  const videoOptions = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 1,
      loop: 1
    }
  };

  return (
    <div className="Login" style={{maxWidth: 600, margin: '50px auto'}}>
      <YouTube videoId="fWhNt2xAGwQ" opts={videoOptions} />
      <h1> Nice Smooch! </h1>
      <h3> 
        Now, my programming tells me this invite is exclusively for {nameString}
      </h3>
      <h3>    
         To ensure you are who you claim you are, please answer 
         this personalized skill testing question:
      </h3>
      <h3> {question} </h3>
      <input
        onChange={(e) => props.setAnswer(e.target.value)}
      />
      <button onClick={submitAnswer}> Submit </button>
    </div>
  );
}

export default Login;
