import React, { useState } from 'react';
import './App.css';
import Login from './Login';
import GuestManager from './GuestManager';
import MobilePage from './MobilePage';
import SmoochManager from './SmoochManager';
import { Guest } from './types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {isMobile} from 'react-device-detect';


function App() {
  let familyId = parseInt(window.location.pathname.split('/')[1]) || 10000;
  let [guests, setGuests] = useState<Array<Guest>>([]);
  let [answer, setAnswer] = useState("");
  let [smooched, setSmooched] = useState(false);

  const updateGuests = (updatedGuests: Array<Guest>) => {
    // fetch(`http://localhost:9000/api/guest/${familyId}`, {
    fetch(`https://kenyamarryme.herokuapp.com/api/guest/${familyId}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        guests: updatedGuests,
        answer: answer
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log(data)
      setGuests(data.guests);
      toast("Saved! See You Soon!");
    })
    .catch((error) => {
      console.log("ERROR:", error)
      toast("ERROR!!!");
    })
  }

  const page = !smooched 
    ? <SmoochManager onSmoochSuccess={setSmooched} smooched />
    : guests.length > 0
      ? <GuestManager guests={guests} onGuestsUpdated={updateGuests} />
      : <Login 
          familyId={familyId} 
          onGuestsFetched={setGuests}
          answer={answer}
          setAnswer={setAnswer}
        />

  return (
    <div className="App">
      <ToastContainer />
      { isMobile ? <MobilePage /> : page}
    </div>
  );
}

export default App;