import React from 'react';

function MobilePage () {
  console.log("YEAH")

  return (
      <h1 style={{padding: 10}}> 
        Listen up cutie <br/> <br/>
        We know you're all antsy to come to Mombasa <br /> <br/>
        But take a seat, relax, and get to your desktop computer <br /> <br/>
        This website doesn't work on your fancy mobile phone. <br /> <br />
        😘
      </h1>
  );
}

export default MobilePage;