import React, { useEffect, useState, useRef } from 'react';
import { Guest } from './types';
import './GuestManager.css'
import events from './images/events.png';
import footer from './images/footer.jpg';

function GuestManager(props: {
  guests: Array<Guest>,
  onGuestsUpdated: (guests: Array<Guest>) => void
}) {

  let refs = useRef<{[id: string]: any}>({});

  const nodes = props.guests.map(g => {
    return(
      <div className="GuestManager-guest">
        {g.First} {g.Last}
        <br/>
        Attending?
        <input
          type="checkbox"
          defaultChecked={!!g.RSVP}
          ref={el => refs.current[`${g.id}-checkbox`] = el}
        />
        <br/>
        Email: 
        <input
          defaultValue={g.Email}
          ref={el => refs.current[`${g.id}-email`] = el}
        />
      </div>
    )
  })

  const submitGuests = () => {
    const newGuests: Array<Guest> = [];

    props.guests.forEach(g => {
      newGuests.push({
        ...g,
        Email: refs.current[`${g.id}-email`].value,
        RSVP: !!refs.current[`${g.id}-checkbox`].checked
      })
    })

    props.onGuestsUpdated(newGuests);

    return newGuests;
  }

  return (
    <div className="GuestManager">
      <div className='GuestManager-info'>
        <div className='GuestManager-infobody'>
          <img src={events} className="GuestManager-eventpic" />
          <h3> Where you'll stay: </h3>
          A hotel in Mombasa Kenya! We'll have more info for you in the coming weeks for this :) Be sure to input your email.
          <h3> What to book: </h3>
          Figure out your vaccines, get a visa, and fly into Mombasa International Airport before the first event! <br/>
          Pro tip. It's easier to book a flight into Nairobi and a separate flight into Mombasa after.
          <br />
          <br/>
          <h3> More details: </h3>
          Once you book your flight into Nairobi (Jomo Kenyatta International Airport), you can either explore Nairobi for a couple of days, or fly straight to Mombasa (Moi International Airport). 
          <br/>
          <br/>
          To book your flight from Nairobi into Mombasa, you can either fly via JamboJet, or Kenya Airways. Our preference is Kenya Airways, but both do the job.
          If you book on Kenya Airways, use the discount code SUNSA for 15% off. 
          <br/>
          <br/>
          The duration of the wedding festivities are the evening of Tuesday February 28th to the afternoon of Saturday March 4th. 
          <br/>
          <br/>
          If you choose to explore Kenya or East Africa before or after, here are some of our suggestions:
          <br/>
          <br/>
          <h3>Masai Mara</h3>
          One of Khallil and Salima’s most favourite places in the world, and an incredible safari experience. Staying at a camp in the Mara is an experience like no other - true luxury in the wild. You can either fly to Nairobi and drive to the Mara - the drive is roughly 5 hours, or you can take a small flight from Wilson Airport in Nairobi which takes approximately an hour. You can book this through Safarilink or AirKenya. 
          <br/>
          <br/>
          <h3>Zanzibar</h3>
          Zanzibar is a Tanzanian archipelago off the coast of East Africa. On its main island, Unguja, familiarly called Zanzibar, is Stone Town, a historic trade center with Swahili and Islamic influences. You will have to return to Nairobi, and then fly to Zanzibar. Go here for incredible beaches, crystal clear water, and lots of history.
          <br/>
          <br/>
<h3>Lamu</h3>
A UNESCO world heritage site, located just a 30 minute flight from Mombasa. There are no cars on this island, and donkeys are the mode of transportation. Boats take you to dinner, and back to your hotel, and it is truly one of the most unique places you will ever visit.
Hotel recommendation: The Majlis
          <br/>
          <br/>
          <h3>NgoroNgoro Crater, & Serengeti, Tanzania</h3>
Kenya’s safari rival.
A safari experience INSIDE a dormant volcano. It’s the world’s largest inactive, intact & unfilled volcanic caldera, home to ample wildlife & bird species. Serengeti National Park is comparable to the Masai Mara, except much larger. 
          <br/>
          <br/>
          <h3>Mount Kilimanjaro</h3>
For the brave. It is the highest mountain in Africa and the highest single free-standing mountain above sea level in the world: 5,895 metres above sea level and about 4,900 metres above its plateau base. If you would like to climb this beast, it’ll take about 8 days in total. We wouldn’t be putting this here if some of our friends weren’t already planning to climb it. 
          <br/>
          <br/>
          <h3>Nairobi</h3>
Kenya’s capital and where Khallil spent four months living before he met his Kenyan girl. A great city to experience the hustle and bustle, a good food and music scene and lots of things to do.
Hotel recommendation: Crowne Plaza, Sankara, Ole Sereni (overlooking Nairobi National Park).
For those overnighting in Nairobi due to a late flight arrival time into Nairobi, the Sheraton airport hotel is a great option!
          <br/>
          <br/>
          <h3>FAQs</h3>
          What should we expect from a safari? <br/>
    - If you go on a safari it is going to be one of the most peaceful and relaxing things you will do if you enjoy long car rides immersed in nature. If you choose a luxury camp, you will experience glamping at it’s finest - imagine gourmet meals, hot showers and being woken up by the distant sounds of lions roaring whilst being cozy in your bed. 
    <br/><br/>
What’s the bug situation like?<br/>
    - You are going to Africa, there will be some bugs - but nothing you can’t handle. Hakuna Matata! 
    <br/><br/>
 How do I get from the airport to the hotel? <br/>
    - We will have more information for you in the coming months
    <br/><br/>
 What does a safari cost?<br/>
    - Usually between $2000-$2500 per person, but you can definitely find budget options, or more luxury options.

        </div>
        <img src={footer} className="GuestManager-footerpic" />
      </div>
      <div className="GuestManager-guests">
        {nodes}
        <div className="GuestManager-submit" onClick={submitGuests}> Submit </div>
      </div>
    </div>
  );
}

export default GuestManager;