import React, {useLayoutEffect, useState, CSSProperties, useRef} from 'react';
import bg_img from './images/bg_smooch.png'
import fg_img from './images/khallil_smooch.png'
import './SmoochManager.css'

function SmoochManager(props: {
  smooched: boolean,
  onSmoochSuccess: (smooch: boolean) => void
}) {

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [aligned, setAligned] = useState(false);

  const bgRef = useRef<HTMLImageElement>(null);

  useLayoutEffect(() => {
    document.onmousemove = (event) => {
      var e = event;
      setX(e.clientX);
      setY(e.clientY);

      // y = 336
      // x - ref.current.offsetLeft = 219
      if (bgRef && bgRef.current) {
        const yGood = 333 < e.clientY && e.clientY < 339;
        const xAmt = e.clientX - bgRef.current.offsetLeft;
        const xGood = 215 < xAmt && xAmt < 223;
        setAligned(yGood && xGood);
      }
    };
  });

  const checkSmooch = () => {
    if (aligned) {
      props.onSmoochSuccess(true);
    }
  }

  const fgStyles: CSSProperties = {
    backgroundColor: 'transparent',
    top: y,
    left: x,
    position: "absolute",
    transform: "translate(-50%, -50%)",
    cursor: 'none',
    width: 438
  }

  return (
    <div className="SmoochManager">
      <div onClick={checkSmooch}>
        <img src={fg_img} style={fgStyles} />
        <img src={bg_img} className="SmoochManager-bg" ref={bgRef} />
      </div>
      <h1>
        { aligned ? 'CLICK TO SMOOCH' : 'SMOOCH TO ACCEPT TERMS'}
      </h1>
      <h3 style={{maxWidth: 600, margin: '0 auto'}}>
        By completing the smooch you agree to leave behind all your stresses, judgement, and worries. You will be carefree
        and ready to celebrate life surrounded by the people you love. You are physically prepared to dance until the sun rises. 
        You won't wear no ties and will expose your thighs because on 3/3/23 you're
        going to Mombasa!
      </h3>
    </div>
  );
}

export default SmoochManager;
